<script setup>
    import {
        ACESFilmicToneMapping,
        AgXToneMapping,
        CineonToneMapping,
        ColorManagement,
        CustomToneMapping,
        LinearToneMapping,
        NeutralToneMapping,
        NoToneMapping,
        ReinhardToneMapping,
    } from 'three';

    import { GLTFLoader, KTXLoader, TextureLoader } from '@resn/gozer-loading';
    import { Renderer } from '@resn/gozer-vue';

    import { Bounds } from '~/components/gl/Bounds';
    import PageLoader from '~/components/PageLoader.vue';
    import { NoiseTextures } from '~/providers/NoiseTexturesProvider';
    import { PostProcessing } from '~/providers/PostProcessingComposer';
    import { ThreePrerender } from '~/providers/ThreePrerender';
    import MainProvider from '~/providers/white-elephant/MainProvider.vue';

    const onRenderSetup = ({ renderer }) => {
        console.log('Render setup complete');

        KTXLoader.initGlobalKTX2Loader('/three/basis/', renderer);
        KTXLoader.setGlobals({ renderer });
        TextureLoader.setGlobals({ renderer });
        GLTFLoader.setGlobals({ renderer, ktxLoader: KTXLoader.globalKTX2Loader });

        renderer.setClearColor(0x000000, 1);
        ColorManagement.enabled = true;

        window.renderer = renderer;
        window.toneMappingOptions = {
            NoToneMapping: NoToneMapping,
            LinearToneMapping: LinearToneMapping,
            ReinhardToneMapping: ReinhardToneMapping,
            CineonToneMapping: CineonToneMapping,
            ACESFilmicToneMapping: ACESFilmicToneMapping,
            AgXToneMapping: AgXToneMapping,
            NeutralToneMapping: NeutralToneMapping,
            CustomToneMapping: CustomToneMapping,
        };

        renderer.toneMapping = NeutralToneMapping;
    };
</script>

<template>
    <MainProvider>
        <Renderer ref="refRenderer" @setupComplete="onRenderSetup" :autoResize="true">
            <ThreePrerender>
                <PostProcessing>
                    <NoiseTextures>
                        <Bounds type="white-elephant">
                            <main id="main">
                                <slot />
                            </main>
                        </Bounds>
                    </NoiseTextures>
                </PostProcessing>
            </ThreePrerender>
        </Renderer>
        <PageLoader />
    </MainProvider>
</template>

<style lang="scss" scoped>
    #main {
        min-height: 100vh;
    }
</style>
